
import { defineComponent } from 'vue';
import TextInput from '@/Components/Shared/TextInput.vue';
import { helpers, required, sameAs } from '@vuelidate/validators';
import Card from '@/Components/Shared/Card.vue';
import useVuelidate from '@vuelidate/core';
import comunas from '@/Data/Comunas.data';
import cities from '@/Data/Cities.data';
import girosSII from '@/Data/Giros.data';
import Multiselect from '@vueform/multiselect';
import { kycService } from '@/Services/Kyc.service';
import { mapActions, mapState } from 'vuex';
import { KycCodeEnum } from '@/Common/Enums/KycCode.enum';
import TermsConditionsLink from '@/Components/Shared/TermsConditionsLink.vue';

export default defineComponent({
  name       : 'ShopCard',
  components : {
    TextInput,
    Card,
    Multiselect,
    TermsConditionsLink,
  },
  setup () {

    return {
      v$: useVuelidate()
    };

  },
  data() {

    return {
      form: {
        address  : '',
        city     : '',
        town     : '',
        category : {
          id   : '',
          name : '',
        },
        consentiment: false,
      },
      comunas,
      cities,
      girosSII,
    };

  },
  computed: {
    ...mapState('session', [
      'shopConfig'
    ]),
  },
  validations () {

    return {
      form: {
        address: {
          required: helpers.withMessage('', required),
        },
        city: {
          required: helpers.withMessage('Debe seleccionar una ciudad', required),
        },
        town: {
          required: helpers.withMessage('Debe seleccionar una comuna', required),
        },
        category: {
          id: {
            required: helpers.withMessage('Debe seleccionar un giro', required),
          },
          name: {
            required: helpers.withMessage('Debe seleccionar un giro', required),
          },
        },
        consentiment: {
          sameAs: helpers.withMessage('Para continuar debe aceptar los términos y condiciones', sameAs(true)),
        },
      },
    };

  },
  methods: {
    ...mapActions('app', [
      'setKyc',
    ]),
    async onSubmit() {

      try{

        await this.v$.$validate();

        if(!this.v$.$invalid) {

          await kycService.init({ ...this.form, enroller_code: this.shopConfig.enroller_code, commerce_tax_id: this.shopConfig.commerce_tax_id });
          this.setKyc({ code: KycCodeEnum.Finish });

        }

      }catch (error: any) {

        console.log(error);

        if (error?.response?.data.message === 'No se puede activar el kyc') {
          
          this.$router.push('/shop/list');
          
        }

      }

    },
    onChange(e: any, field: string) {

      this.form = { ...this.form, [field]: e };

    },
    focusin(e: any) {

      const multiselect: any = this.$refs[e];
      multiselect.open();

    },
  }
});
