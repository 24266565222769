
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'SidebarMenu',
  data() {

    return {
      items: [
        {
          name : 'Dashboard',
          link : 'dashboard',
          icon : 'chart-pie',
        },
        {
          name : 'Cartola RedPay',
          link : 'redpay',
          icon : 'file-table-box-outline',
        },
        {
          name : 'Cartola de Devoluciones',
          link : 'chargeback',
          icon : 'file-table-box-outline',
        },
        {
          name : 'Cartola de Excedentes',
          link : 'surplus',
          icon : 'file-table-box-outline',
        },
      ]
    };

  },
  computed: {
    ...mapState('session', [
      'shopConfig',
      'canActivate'
    ]),
    currentPath() {

      return this.$router.currentRoute.value.name;

    },
    commerce_hash() {

      return this.$route.params.commerce_hash;

    }
  },
  watch: {
    currentPath(value) {

      console.log(value);

    },
    canActivate(value) {

      if(value === false) {

        this.items = this.items.filter(objeto => objeto.link !== 'redpay');

      }

    }
  },
  mounted() {

    const sidebarToggle = document.body.querySelector('#sidebarToggle');
    const sidebar = document.body.querySelector('#sidebar');

    if (sidebarToggle) {

      sidebarToggle.addEventListener('click', () => {

        if(sidebar) {

          if(!sidebar.classList.contains('collapsed')) {

            sidebar.classList.add('collapsed');

          } else {

            sidebar.classList.remove('collapsed');

          }

        }

      });

    }

  },
  methods: {
    to(namePath: string) {

      this.$router.push({ name: namePath, params: { commerce_hash: this.commerce_hash || this.shopConfig.commerce_hash } });

    }
  }
});
