
import { computed, ComputedRef, defineComponent, onMounted, ref } from 'vue';
import BalanceCard from '@/Components/Home/Partials/BalanceCard.partial.vue';
import ActivationCard from '@/Components/Home/Partials/ActivationCard.partial.vue';
import { accountService } from '@/Services/Account.service';
import { BalanceType } from '@/Common/Interfaces/GetBalanceRequest.interface';
import { useStore } from 'vuex';
import { IShop } from '@/Common/Interfaces/Shop.interface';

export default defineComponent({
  name       : 'CompanyList',
  components : {
    BalanceCard,
    ActivationCard,
  },
  setup() {

    const store = useStore();

    const surplusBalance = ref({
      countable_balance : 0,
      available_balance : 0,
    });

    const chargeBackBalance = ref({
      countable_balance : 0,
      available_balance : 0,
    });

    const redPayBalance = ref({
      countable_balance : 0,
      available_balance : 0,
    });

    const isActivated = ref(true);
    const canActivate = ref(true);

    const shop: ComputedRef<IShop> = computed(() => store.state.session.shopConfig);


    const getRedPayBalance = async () => {

      redPayBalance.value = await accountService.getBalance({
        enroller_code   : shop.value.enroller_code,
        commerce_tax_id : shop.value.commerce_tax_id,
        statement_type  : BalanceType.RedPay,
      });

    };

    const getChargeBackBalance = async () => {

      chargeBackBalance.value = await accountService.getBalance({
        enroller_code   : shop.value.enroller_code,
        commerce_tax_id : shop.value.commerce_tax_id,
        statement_type  : BalanceType.Chargeback,
      });

    };

    const getSurplusBalance = async () => {

      surplusBalance.value = await accountService.getBalance({
        enroller_code   : shop.value.enroller_code,
        commerce_tax_id : shop.value.commerce_tax_id,
        statement_type  : BalanceType.Surplus,
      });

    };

    onMounted(async () => {

      try{

        await getRedPayBalance();

      }catch (error: any) {

        if(error.response.status == 406 && error.response.data?.kyc_status) {

          isActivated.value = false;

        }

        canActivate.value = error.response.data?.can_activate === 0 ? false : true;
        await store.dispatch('session/setCanActivate', canActivate.value);

      }

      try{

        await getChargeBackBalance();

      }catch (error) {

        console.error(error);

      }

      try{

        await getSurplusBalance();

      }catch (error) {

        console.error(error);

      }

    });

    return {
      surplusBalance,
      chargeBackBalance,
      redPayBalance,
      isActivated,
      canActivate,
    };

  },
});
